import {
  Component, forwardRef, Inject,
  Input, Self, ViewChild,
} from '@angular/core';

import { Processor } from '@i3e/processor';

import { DataList } from './data-list';

import { DataPresentation } from './data-presentation';

import { SearchTermComponent } from '@consol/shared/util-component';

import {
  DataLoaderProcessorEngine, ItemProcessorEngine,
  ActionsProcessorEngine, LinksProcessorEngine,
  BackProcessorEngine,
} from '../processor';

export const csDataListComponent = {
  provide: DataList,
  useExisting: forwardRef(() => DataListComponent),
};
export const csDataListRemainComponent = {
  provide: DataList,
  useExisting: forwardRef(() => DataListRemainComponent),
};
@Component({
  selector: 'cs-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: [ './data-list.component.less' ],
  providers: [csDataListComponent],
  exportAs: 'csDataList',
})
export class DataListComponent
extends DataPresentation<DataLoaderProcessorEngine<any> & ItemProcessorEngine & ActionsProcessorEngine & LinksProcessorEngine & BackProcessorEngine> {
  @ViewChild('search') search: SearchTermComponent;
  @Input() filter: any;
  private _condition: boolean;
  @Input('condition') set condition(value: boolean){this._condition = ((value as any === '') || !!value);};
  get condition() {return this._condition;};

  constructor(
    @Self() @Inject(Processor) processor: DataLoaderProcessorEngine<any> & ItemProcessorEngine & ActionsProcessorEngine & LinksProcessorEngine & BackProcessorEngine,
  ) {
    super(processor);

    this._condition = false;
  }

  itemProcess(params: any[], extra: {[key: string]: any}): void {
    if(typeof this.processor.itemProcess === 'function') {
      this.processor.itemProcess(params, extra);
    } else {
      console.error(`itemProcess process not found in processor`, this.processor);
    }
  }

  addSearchOption(name: string, value: any) {
    this.search.focus(() => this.search.addOption(name, value));
  }

  removeSearchOption(name: string) {
    this.search.focus(() => this.search.removeOption(name));
  }
}

@Component({
  selector: 'cs-data-list-remain',
  templateUrl: './data-list-remain.component.html',
  styleUrls: [ './data-list.component.less' ],
  providers: [csDataListRemainComponent],
})
export class DataListRemainComponent
extends DataPresentation<DataLoaderProcessorEngine<any> & ItemProcessorEngine & ActionsProcessorEngine & LinksProcessorEngine & BackProcessorEngine> {
  constructor(
    @Self() @Inject(Processor) processor: DataLoaderProcessorEngine<any> & ItemProcessorEngine & ActionsProcessorEngine & LinksProcessorEngine & BackProcessorEngine,
  ) {
    super(processor);
  }

  itemProcess(params: any[], extra: {[key: string]: any}): void {
    if(typeof this.processor.itemProcess === 'function') {
      this.processor.itemProcess(params, extra);
    } else {
      console.error(`itemProcess process not found in processor`, this.processor);
    }
  }
}
