import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
//import { DeviceDetectorModule } from 'ngx-device-detector';

//import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';

import { I3eModule } from '@i3e';

// TODO: move to core module
import { TokenModule } from '@consol/token';
import { AuthorizationModule } from '@consol/authorization';

import { SharedModule } from './shared';
import { AppCoreModule } from './app-core';
import { CustomModule } from './custom';

import { AppMaterialModule } from './app-material.module';
//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ComponentInfoService } from './shared';

import { appRoutes, DummyComponent } from './app-routes';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent, DummyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
//    DeviceDetectorModule.forRoot(),
    I3eModule.forRoot(),
    SharedModule,
    AppCoreModule.forRoot({
      REST_SERVER_CONFIG: {
        default: {
          host: '',
          path: 'api/'
        },
        specifics: {
          'api': environment.api,
        }
      }
    }),
    TokenModule.forRoot(environment.tokenData),
    AuthorizationModule.forRoot(),
    CustomModule.forRoot(),
    AppMaterialModule,
    RouterModule.forRoot(appRoutes),
    //AppRoutingModule,
  ],
  providers: [
    ComponentInfoService,
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
