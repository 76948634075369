import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AsyncLocalStorageModule } from 'angular-async-local-storage';

import { TokenInterceptor } from './interceptor';

import { TokenConfig } from './model';

import { LoginDataService, TokenService } from './service';

import { LoginComponent, LoginDialogComponent } from './component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AsyncLocalStorageModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
  exports: [
    HttpClientModule,
  ],
  declarations: [
    LoginComponent,
    LoginDialogComponent,
  ],
  entryComponents: [
    LoginDialogComponent,
  ],
  providers: [
    TokenService,
    LoginDataService,
  ]
})
export class TokenModule {
  static forRoot(options: TokenConfig): ModuleWithProviders {
    return {
      ngModule: TokenModule,
      providers: [
        { provide: TokenConfig, useValue: options },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      ]
    };
  }
}
