import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { RoleGuardService as RoleGuard } from '@consol/authorization';

@Component({
  selector: 'cs-dummy-component',
  template: '',
})
export class DummyComponent { }

export const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: '@consol/home/routing.module#HomeRoutingModule', data: {icon: 'home', label: 'HOME',text: 'HOME', name: ' ', backable: false}},
  { path: 'purchase',
    loadChildren: '@consol/purchase/routing.module#PurchaseRoutingModule',
    canLoad: [RoleGuard],
    data: {
      //label: 'PROCUREMENT/จัดซื้อ', icon: 'shopping_cart', text: 'จัดซื้อ', description: 'PU',
      icon: 'shopping_cart', label: 'PU จัดซื้อ', text: 'PU', name: 'จัดซื้อ', description: 'PROCUREMENT',
      allowedRoles: ['PURCHASE_ACCESS'],
      backable: false,
    },
  },
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Receive', icon: 'archive'}},
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Invoice', icon: 'payment'}},
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Account', icon: 'description'}},
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Finance', icon: 'monetization_on'}},
  { path: 'income',
    loadChildren: '@consol/income/routing.module#IncomeRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'shopping_basket', label: 'IN รายได้', text: 'IN', name: 'รายได้', description: 'INCOME',
      allowedRoles: ['INCOME_ACCESS'],
      backable: false,
    }
  },
  { path: 'finance',
    loadChildren: '@consol/finance/routing.module#FinanceRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'monetization_on', label: 'FI การเงิน', text: 'FI', name: 'การเงิน', description: 'FINANCE',
      allowedRoles: ['FINANCE_ACCESS'],
      backable: false,
    }
  },
  { path: 'report',
    loadChildren: '@consol/report/routing.module#ReportRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'insert_chart', label: 'RP รายงาน', text: 'RP', name: 'รายงาน', description: 'REPORT',
      allowedRoles: ['REPORT_ACCESS'],
      backable: false,
    },
  },
  { path: 'master',
    loadChildren: '@consol/master/master-routing.module#MasterRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'create_new_folder', label: 'MT ข้อมูล', text: 'MT', name: 'ข้อมูล', description: 'MASTER',
      allowedRoles: ['MASTER_ACCESS'],
      backable: false,
    },
  },
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Admin', icon: 'supervisor_account'}},
  { path: 'system',
    loadChildren: '@consol/system/routing.module#SystemRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'build', label: 'SYSTEM', text: 'SYSTEM', name: ' ', description: 'SYSTEM',
      allowedRoles: ['SYSTEM_ACCESS', 'ADMIN'],
      backable: false,
    },
  },
  { path: 'setting',
    loadChildren: '@consol/setting/setting-routing.module#SettingRoutingModule',
    canLoad: [RoleGuard],
    data: {
      icon: 'settings', label: 'SETTING', text: 'SETTING', name: ' ', description: 'SETTING',
      allowedRoles: ['SETTING_ACCESS'],
      backable: false,
    },
  },
  //{ path: 'billing-note', loadChildren: '@consol/billing-note/routing.module#BillingNoteRoutingModule', data: {label: 'BILL', icon: 'payment', backable: false}},
  //{ path: 'aaaaa', loadChildren: '@consol/aaaaa/routing.module#AaaaaRoutingModule', data: {label: 'AAAAA', icon: 'home', backable: false}},
  //{ path: 'aaaab', loadChildren: '@consol/aaaab/routing.module#AaaabRoutingModule', data: {label: 'BBBBB', icon: 'home', backable: false}},
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Help & Feedback', icon: 'feedback'}},
  //{ path: 'dummy', component: DummyComponent, data: {label: 'Dummy', icon: 'insert_emoticon'}},
  /*{ path: 'rq',
    loadChildren: '@consol/request-for-quotation/request-for-quotation-routing.module#RequestForQuotationRoutingModule',
    canLoad: [RoleGuard],
    data: {
      label: 'RQ', icon: 'settings',
      allowedRoles: ['SETTING_ACCESS','ADMIN'],
      backable: false,
    },
  },*/

  // {
  //   path: 'my-test',
  //   loadChildren: '@consol/my-test/my-test-routing.module#MyTestRoutingModule'
  // },

];
/*
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  declarations: [DummyComponent],
})
export class AppRoutingModule {}
*/
