import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthorizationService } from '../service';

const directiveName = 'csAuthRoles';

@Directive({
  selector: `[${ directiveName }]`,
})
export class AuthorizeRolesDirective {
  private hasView: boolean;

  @Input(directiveName) set csAuthRoles(roles: string[]) {
    const allowed = this.authorizationService.allowed(roles);

    if (allowed && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!allowed && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private authorizationService: AuthorizationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.hasView = false;
  }
}
