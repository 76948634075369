import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ServerResolverService } from '@consol/core';
import { CommandInfo } from '@consol/shared';

import { FileUploadService } from './file-upload.service';
import { map } from 'rxjs/operators';

@Injectable()
export class FileUploadApiService implements FileUploadService {
  constructor(
    private http: HttpClient,
    private sr: ServerResolverService
  ) { }

  fileUrl(uuid: string): string {
    return this.sr.getUrl('api', `file/${ uuid }`);
  }

  upload(data: any): Observable<CommandInfo> {
    // return this.http.put(this.sr.getUrl('api', 'file'), data).pipe(
    //   map((body: CommandInfo) => {
    //     let data = body.data || {};
    //     return data;
    //   })
    // );
    const formData = new FormData();
    formData.set('file', data);
    return this.http.post(this.sr.getUrl('api', 'file'), formData).pipe(
      map((body: CommandInfo) => {
        let data = body.data || {};
        return data;
      })
    );
  }

  delete(uuid: string): Observable<CommandInfo> {
    return this.http.delete(this.sr.getUrl('api', `file/${ uuid }`)).pipe(
      map((body: CommandInfo) => {
        let data = body.data || {};
        return data;
      })
    );
  }
}
