import { Component } from '@angular/core';

import { SystemEmployeeService } from '@consol/core/service/system-employee.service';
import { AuthorizationService } from '@consol/authorization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  constructor(
    private authorizationService: AuthorizationService,
    private systemEmployeeService: SystemEmployeeService,
  ) {
    this.loadSystemUser();
  }

  protected loadSystemUser() {
    this.authorizationService.loadSystemUser(true).subscribe(
      (systemUser) => {
        if(systemUser) {
          this.systemEmployeeService.loadEmployee(systemUser).subscribe(
            //(employee) => console.debug(employee)
          );
        }
      }
    );
  }

  get systemUser() { return this.authorizationService.getSystemUser(); }
}
