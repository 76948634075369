import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest,
  HttpEvent, HttpResponse, HttpErrorResponse,
}  from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { switchMap, catchError } from 'rxjs/operators';

import { TOKEN_HTTP_HEADER_NAME } from '../predefine';

import { TokenService } from '../service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) { }

  private assignTokenToRequest(req: HttpRequest<any>, headerToken: string): HttpRequest<any> {
    return req.clone({
      headers: req.headers.set(TOKEN_HTTP_HEADER_NAME, headerToken),
    });
  }

  private handleToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tokenService.headerToken$.pipe(
      switchMap((headerToken) => {
        return next.handle(this.assignTokenToRequest(req, headerToken));
      }),
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.headers.has(TOKEN_HTTP_HEADER_NAME)) {
      return this.handleToken(req, next).pipe(
        // May be local token doesn't sync with server
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              return this.tokenService.renewToken().pipe(
                switchMap(() => this.handleToken(req, next)),
              );
            }
          }
          return throwError(error);
        }),
      );
    }

    return next.handle(req);
  }
}
