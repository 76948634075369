import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { Observable, merge } from 'rxjs';

import { FormService } from '@i3e/form-service';

import { Corporate } from '../model';

import { PersonDataFormService } from '../person-data';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class CorporateFormService extends FormService<Corporate> {
  constructor(
    fb: FormBuilder,
    private personDataFormService: PersonDataFormService,
  ) {
    super(fb);
  }

  formConfig(item?: Corporate): {[name: string]: any} {
    item = item || new Corporate();

    let _code = null;
    if(item.code) _code = item.code.split('/')[0];

    return Object.assign(this.personDataFormService.formConfig(item), {
      dtype: [item.dtype || 'corporate', [Validators.required]],
      code: [item.code || null, [Validators.required]],
      _code: [_code || null, [
        Validators.required,
        Validators.pattern(/^\d*$/),
      ]],
      registrationDate: [item.registrationDate || null, []],
      category: [item.category || null, [Validators.required]],
      corporateName: [item.corporateName || null, [Validators.required]],
      main: [(item.main === true)? true : item.main, (item.branch)? [] : [Validators.required]],
      branch: [{ value: item.branch || null, disabled: item.main }, [Validators.required]],
    });
  }

  formAssignChanges(formGroup: FormGroup): FormGroup {
    this.personDataFormService.formAssignChanges(formGroup);

    merge(
      formGroup.get('category').valueChanges,
      formGroup.get('corporateName').valueChanges,
    ).subscribe(() => {
      const category = formGroup.get('category').value;
      const corporateMame = formGroup.get('corporateName').value;
      formGroup.get('name').setValue(`${category} ${corporateMame}`.trim());
    });

    formGroup.get('main').valueChanges.subscribe((value)=>{
      const branchControl = formGroup.get('branch');

      if(value){
        branchControl.setValue(null);
        branchControl.disable();
      }
      else branchControl.enable();

      //branchControl.markAsTouched();
      branchControl.updateValueAndValidity();
    });

    merge(
      formGroup.get('_code').valueChanges,
      formGroup.get('main').valueChanges,
      formGroup.get('branch').valueChanges,
    ).pipe(
      debounceTime(300),
    ).subscribe(() => {
      const main:boolean = formGroup.get('main').value;
      const _code:string = formGroup.get('_code').value;
      const branch:string = formGroup.get('branch').value;
      const codeCtrl = formGroup.get('code');
      if(_code) {
        const codes = [_code];
        if(!main) codes.push(branch);
        codeCtrl.setValue(codes.join('/'));
      } else {
        codeCtrl.setValue(_code);
      }
      codeCtrl.updateValueAndValidity();
    });

    return formGroup;
  }
}
