import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { SharedModule } from '@consol/shared';

//import { I3eModule } from '@i3e';

import {
  AuthorizationService,
  RoleGuardService,
} from './service';

import { AuthorizationApiService } from './service/authorization-api.service';

import { AuthorizeRolesDirective } from './directive';

@NgModule({
  imports: [
    CommonModule,
    //I3eModule,
    //SharedModule,
    //MaterialModule, // now use from shared module
  ],
  exports: [
    AuthorizeRolesDirective,
  ],
  declarations: [
    AuthorizeRolesDirective,
  ],
})
export class AuthorizationModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AuthorizationModule,
      providers: [
        { provide: AuthorizationService, useClass: AuthorizationApiService },
        RoleGuardService,
      ]
    };
  }
}
