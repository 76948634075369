import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AuthorizationModule } from '@consol/authorization';
//import { TokenModule } from '@consol/token';

import { EntryPipe } from './entry.pipe';
import { AccessTokenPipe, NumberFormatPipe, TimezoneLessPipe } from './pipe';

import { SharedMaterialModule } from './shared-material.module';
import {
  NavigationBackDirective, NavigationBackComponent
} from './navigation-back.component';
import { ActiveMediaDirective } from './active-media.directive';
import { FormControlHtmlErrorDirective } from './form-control-html-error.directive';
import { ItemNotFoundComponent } from './item-not-found.component';
import { PaginationComponent } from './pagination.component';
import { SearchableControlComponent } from './searchable-control.component';
import { ArraySourceControlComponent } from './array-source-control.component';
import { FormControlErrorDirective } from './form-control-error.component';
import { ItemDeleteConfirmComponent } from './item-delete-confirm.component';

import { RouterDividerComponent } from './router-divider.component';

import { ItemDataArrayComponent, ItemDataArrayItemComponent } from './item-data-array.component';

import { ItemListComponent } from './item-list.component';
import { ItemViewComponent } from './item-view.component';
import { ItemEditComponent } from './item-edit.component';

// new
import {
  ContextProcessorEngineDirective,
  FormRoutingProcessorEngineDirective, FormSimpleProcessorEngineDirective,
  ItemFunctionProcessorEngineDirective, ItemRoutingProcessorEngineDirective,
  BackRoutingProcessorEngineDirective,
} from './processor';
import {
  DefaultValueDirective,
  SearchableItemControlComponent, SearchableArrayControlComponent,
} from './data-control';
import { DialogOpenDirective } from './dialog';
import {
  DataListComponent, DataViewComponent, DataFormComponent,
  DataListSelectedDirective, DataListRemainComponent
} from './data-presentation';
import { RoutingStateDirective } from './global-state';

import { ProcessDescriptionService } from './processor';

import {
  SearchTermComponent, PaginatorComponent,
  BackButtonComponent,
} from './util-component';

import { WrapHardDirective } from './directive/wrap-hard.directive';
import { MinValidatorDirective } from '@consol/shared/directive/min-validator.directive';
import { MaxValidatorDirective } from '@consol/shared/directive/max-validator.directive';

@NgModule({
  imports: [
    CommonModule,
//    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    FlexLayoutModule,
//    TokenModule,
    AuthorizationModule,
  ],
  exports: [
    CommonModule,
//    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
//    TokenModule,
    AuthorizationModule,

    EntryPipe,
    AccessTokenPipe, NumberFormatPipe, TimezoneLessPipe,

    NavigationBackDirective, NavigationBackComponent,
    ActiveMediaDirective,
    FormControlHtmlErrorDirective,
    ItemNotFoundComponent,
    PaginationComponent,
    SearchableControlComponent, ArraySourceControlComponent,
    FormControlErrorDirective,
    ItemDeleteConfirmComponent,
    RouterDividerComponent,
    ItemDataArrayComponent, ItemDataArrayItemComponent,
    ItemListComponent, ItemViewComponent, ItemEditComponent,
    SharedMaterialModule, // for convinion use

    ContextProcessorEngineDirective,
    FormRoutingProcessorEngineDirective, FormSimpleProcessorEngineDirective,
    ItemFunctionProcessorEngineDirective, ItemRoutingProcessorEngineDirective,
    BackRoutingProcessorEngineDirective,

    DefaultValueDirective,
    SearchableItemControlComponent, SearchableArrayControlComponent,

    DialogOpenDirective,

    DataListComponent, DataViewComponent, DataFormComponent,
    DataListSelectedDirective, DataListRemainComponent,

    RoutingStateDirective,

    SearchTermComponent, PaginatorComponent,
    BackButtonComponent,

    WrapHardDirective,

    MinValidatorDirective,
    MaxValidatorDirective,
  ],
  declarations: [
    EntryPipe,
    AccessTokenPipe, NumberFormatPipe, TimezoneLessPipe,

    NavigationBackDirective, NavigationBackComponent,
    ActiveMediaDirective,
    FormControlHtmlErrorDirective,
    ItemNotFoundComponent,
    SearchTermComponent, PaginationComponent,
    SearchableControlComponent, ArraySourceControlComponent,
    FormControlErrorDirective,
    ItemDeleteConfirmComponent,
    RouterDividerComponent,
    ItemDataArrayComponent, ItemDataArrayItemComponent,
    ItemListComponent, ItemViewComponent, ItemEditComponent,

    ContextProcessorEngineDirective,
    FormRoutingProcessorEngineDirective, FormSimpleProcessorEngineDirective,
    ItemFunctionProcessorEngineDirective, ItemRoutingProcessorEngineDirective,
    BackRoutingProcessorEngineDirective,

    DefaultValueDirective,
    SearchableItemControlComponent, SearchableArrayControlComponent,

    DialogOpenDirective,

    DataListComponent, DataViewComponent, DataFormComponent,
    DataListSelectedDirective, DataListRemainComponent,

    RoutingStateDirective,

    SearchTermComponent, PaginatorComponent,
    BackButtonComponent,

    WrapHardDirective,

    MinValidatorDirective,
    MaxValidatorDirective,
  ],
  entryComponents: [ ItemDeleteConfirmComponent ],
  providers: [
    ProcessDescriptionService,
  ],
})
export class SharedModule { }
