import {
  Directive, forwardRef, Input,
} from '@angular/core';

import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';

import { distinctUntilChanged } from 'rxjs/operators';

import { isObjectEqual } from '@i3e/function';

import { dataLoaderDirectiveName } from '../predefine';

import { DataLoaderEngine } from '../data-loader-engine';

export const i3eExistingLoaderEngine: any = {
  provide: DataLoaderEngine,
  useExisting: forwardRef(() => ExistingLoaderDirective),
};

@Directive({
  selector: `[${ dataLoaderDirectiveName }="existing"]`,
  providers: [ i3eExistingLoaderEngine ],
})
export class ExistingLoaderDirective<T>
implements DataLoaderEngine<T, T> {
  private dataSubject: Subject<T>;

  @Input('existingData') set existingData(data: T) {
    this.dataSubject.next(data);
  }

  constructor() {
    this.dataSubject = new ReplaySubject<T>(1);
  }

  observable(): Observable<T> {
    return this.dataSubject.asObservable().pipe(
      distinctUntilChanged(isObjectEqual)
    );
  }

  load(data: T): Observable<T> {
    return of(data);
  }

  search(params: {[key: string]: any}): void { }
}
