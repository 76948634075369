import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RestServerConfig } from './model';

import { ServerResolverService } from './service';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  declarations: [
  ],
  entryComponents: [
  ],
  providers: [
  ]
})
export class CoreModule {
  static forRoot(options: RestServerConfig): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: RestServerConfig, useValue: options },
      ]
    };
  }
}
