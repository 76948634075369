import {
  NgModule, ModuleWithProviders,
  Optional, SkipSelf
} from '@angular/core';

import { RouterModule } from '@angular/router';

import { SharedModule } from '@consol/shared';

import { RestServerConfig, ServerResolverService } from '@consol/core';
import { SystemEmployeeService } from '@consol/core/service/system-employee.service';

import { BlankLayoutComponent, AppLayoutComponent } from './component';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
  ],
  exports: [
    BlankLayoutComponent, AppLayoutComponent,
  ],
  declarations: [
    BlankLayoutComponent, AppLayoutComponent,
  ],
  providers: [ ServerResolverService, SystemEmployeeService ],
})
export class AppCoreModule {
  constructor (@Optional() @SkipSelf() parentModule: AppCoreModule) {
    if(parentModule){
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(options: {[configType: string]: any}): ModuleWithProviders {
    return {
      ngModule: AppCoreModule,
      providers: [
        { provide: RestServerConfig, useValue: options['REST_SERVER_CONFIG'] }
      ]
    };
  }
}
