import { StateLabelConfig } from '@i3e/global-state';

import { Account } from '@consol/core';

export class AccountStateLabelConfig<T extends Account> implements StateLabelConfig {
  constructor(private entityName: string, private externalCallback: () => string = null) { }

  readonly for = {
    select: (data: any[]): string => {
      return (this.externalCallback && this.externalCallback()) || this.entityName;
    },

    list: (data: T[]): string => {
      return (this.externalCallback && this.externalCallback()) || this.entityName;
    },

    item: (data: T): string => {
      if(data.id) return data.code;
      return `${ this.entityName }`;
    },

    view: (data: T): string => {
      if(data.id) return data.code;
      return `View - ${ this.entityName }`;
    },

    form: (data: T): string => {
      if(data.id) return `Edit - ${ data.code }`;
      return `Add - ${ this.entityName }`;
    },
  };
}
