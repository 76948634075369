import { Directive, Input, HostBinding } from "@angular/core";
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from "@angular/forms";

@Directive({
  selector: '[min]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }]
})
export class MinValidatorDirective implements Validator {
  private onValidatorChange?: () => void;
  private _min: number | null = null;

  @HostBinding('attr.min')
  @Input()
  set min(value) {
    if(value !== null) {
      if(typeof value === 'string') {
        value = +value;
      }

      value = Math.round(value * 1000) / 1000;
    }

    this._min = value;

    if(this.onValidatorChange) {
      this.onValidatorChange();
    }
  }

  get min() {
    return this._min;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.min === null) {
      return null;
    }

    const accual = +control.value;
    const min = this.min;

    if(isNaN(accual) || accual < min) {
      return {
        min: {
          min: min,
          accual: accual,
        },
      };
    } else {
      return null;
    }
  }
}
