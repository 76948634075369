import { Observable } from 'rxjs';

import { SystemUser } from '../model';

export abstract class AuthorizationService {
  abstract loadSystemUser(): Observable<SystemUser>;
  abstract loadSystemUser(clearBeforeLoading: boolean): Observable<SystemUser>;

  abstract clearSystemUser(): void;
  abstract getSystemUser(): SystemUser;
  abstract affectiveRoles(roles: string[]): string[];
  abstract allowed(roles: string[]): boolean;

  readonly authenticated$: Observable<void>;
}
