import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, forwardRef, Optional, Self } from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';

import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'cs-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less'],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  },
  providers: [
    { provide: MatFormFieldControl, useExisting: FileUploadComponent },
  ],
  exportAs: 'csFileUpload',
})
export class FileUploadComponent implements ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  file: FormControl;

  stateChanges = new Subject<void>();

  focused = false;

  controlType = 'cs-file-upload';

  get errorState() {
    return (this.ngControl)? (this.ngControl.touched && this.ngControl.invalid) : false;
  }

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  // TODO: change to controlType
  id = `cs-file-upload-${FileUploadComponent.nextId++}`;

  describedBy = '';

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required() { return this._required; }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled() { return this._disabled; }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input() accept: string = null;

  @Input()
  get value(): string | null {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
    this.stateChanges.next();
  }
  private _value = null;

  private origin = null;
  private fileName = null;

  _onChange: any;
  _onTouched: any;

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef,
    private service: FileUploadService,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    /*
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      let input = this.elRef.nativeElement.querySelector('input');
      if(input) input.focus();
    }
    */
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  writeValue(obj: any): void {
    this.origin = obj;
    this.value = obj;
  }

  upload(files: any[]) {
    for(const file of files) {
      this.service.upload(file).subscribe((info) => {
        this.fileName = file.name;
        this.value = info.uuid;
        this._onChange(this.value);
      });
    }
  }
  
  get updated(): boolean {
    return this.origin != this.value;
  }

  remove() {
    if(this.value && (this.updated)) {
      this.service.delete(this.value).subscribe((info) => {
        this.value = this.origin;
        this._onChange(this.value);
        this.elRef.nativeElement.querySelector('input').value = '';
      });
    }
  }

  get fileUrl() {
    return (this.value)? this.service.fileUrl(this.value) : null;
  }
}
