import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, ConnectableObservable } from 'rxjs';
import { of } from 'rxjs';

import { map, mergeMap, catchError, filter, publish, publishLast, switchMap, tap } from 'rxjs/operators';

import { AsyncLocalStorage, JSONSchema } from 'angular-async-local-storage';

import { TOKEN_HTTP_HEADER_NAME } from '../predefine';

import { TokenConfig, TokenData, LoginData } from '../model';

import { LoginDataService } from './login-data.service';

function capitalizeString(text: string) {
  return text.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
}

function noopFunction() { }

@Injectable()
export class TokenService {
  static readonly TokenName: string = 'oauthToken';
  static readonly TokenType: string = 'Basic';
  static readonly TokenLetency: number = 3000;

  protected remoteToken$: Observable<TokenData> | null;

  constructor(
    private config: TokenConfig,
    private localStorage: AsyncLocalStorage,
    private loginDataService: LoginDataService,
    private httpClient: HttpClient,
  ) {
    this.remoteToken$ = null;
  }

  protected assignClientToHeader(headers: HttpHeaders): HttpHeaders {
    const clientCredential = btoa(`${this.config.client_id}:${this.config.secret}`);

    return headers.set(TOKEN_HTTP_HEADER_NAME, `${capitalizeString(TokenService.TokenType)} ${clientCredential}`);
  }

  protected createHttpOptions() {
    const headers = new HttpHeaders();

    return {
      headers: this.assignClientToHeader(headers),
    };
  }

  protected request(url: string, data: any): Observable<TokenData> {
    return this.httpClient.post(url, data, this.createHttpOptions()).pipe(
      catchError((error) => of(null)),
      switchMap((tokenData) => this.storeToken(tokenData)),
      switchMap(() => {
        this.remoteToken$ = null;
        return this.tokenData$;
      })
    );
  }

  protected tokenRequest(data: any): Observable<TokenData> {
    return this.request(this.config.url + '/token', data);
  }

  protected storeToken(tokenData: any): Observable<boolean> {
    if (!tokenData) {
      return this.localStorage.removeItem(TokenService.TokenName);
    }

    return this.localStorage.setItem(TokenService.TokenName, Object.assign(
      {},
      tokenData,
      { expires_at: ((new Date()).getTime() + (tokenData.expires_in * 1000)) - TokenService.TokenLetency },
      //{expires_at: ((new Date()).getTime() + 3000)},
    ));
  }

  protected refreshTokenRequest(refreshToken: string): Observable<TokenData> {
    return this.tokenRequest({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });
  }

  protected passswordTokenRequest(loginData: LoginData): Observable<TokenData> {
    return this.tokenRequest({
      grant_type: 'password',
      username: loginData.username,
      password: loginData.password,
      scope: loginData.scope,
    });
  }

  protected get localTokenData$(): Observable<TokenData> {
    const schema: JSONSchema = {
      properties: {
        access_token: { type: 'string' },
        token_type: { type: 'string' },
        scope: { type: 'string' },
        refresh_token: { type: 'string' },
        expires_at: { type: 'integer' },
      },
      required: ['access_token', 'token_type', 'scope', 'refresh_token', 'expires_at'],
    };

    return this.localStorage.getItem(TokenService.TokenName/*, { schema }*/).pipe(
      catchError((error) => {
        console.error('toke service:', error);
        return of(null);
      }),
    );
  }

  protected setLocalTokenData(tokenData: TokenData): Observable<boolean> {
    return this.localStorage.setItem(TokenService.TokenName, tokenData);
  }

  protected removeLocalTokenData(): Observable<boolean> {
    return this.localStorage.removeItem(TokenService.TokenName);
  }

  protected forceExpires(): Observable<boolean> {
    return this.localTokenData$.pipe(
      switchMap((tokenData) => {
        if (tokenData) {
          tokenData.expires_at = 0;
          return this.setLocalTokenData(tokenData);
        }

        return of(true);
      }),
    );
  }

  protected get tokenData$(): Observable<TokenData> {
    if (this.remoteToken$) return this.remoteToken$;

    return this.localTokenData$.pipe(
      switchMap((tokenData) => {
        const remoteToken$ = ((tokenData) => {
          if (!tokenData) {
            /*
            return this.passswordTokenRequest({
              username: 'root',
              password: '1234',
              scope: 'comp3',
            });
            */
            return this.loginDataService.getLoginData().pipe(
              //tap(console.debug, console.debug, () => console.debug('complete!!')),
              switchMap((loginData) => this.passswordTokenRequest(loginData)),
            );
          }

          if (tokenData.expires_at < (new Date()).getTime()) {
            return this.refreshTokenRequest(tokenData.refresh_token);
          }

          return null;
        })(tokenData);

        if (remoteToken$) {
          const publish$ = remoteToken$.pipe(publish()) as ConnectableObservable<TokenData>;
          publish$.connect();
          return this.remoteToken$ = publish$;
        }

        return of(tokenData);
      }),
    );
  }

  get headerToken$(): Observable<string> {
    return this.tokenData$.pipe(
      map((tokenData) => `${capitalizeString(tokenData.token_type)} ${tokenData.access_token}`),
    );
  }

  get token$(): Observable<string> {
    return this.tokenData$.pipe(
      map((tokenData) => tokenData.access_token),
    );
  }

  get scope$(): Observable<string> {
    return this.tokenData$.pipe(
      map((tokenData) => tokenData.scope),
    );
  }

  renewToken(callback: () => void = noopFunction): Observable<boolean> {
    return this.forceExpires().pipe(
      tap(callback),
    );
  }

  deleteToken(callback: () => void = noopFunction): Observable<boolean> {
    return this.removeLocalTokenData().pipe(
      tap(console.debug, console.debug, () => console.debug('complete')),
      tap(callback),
    );
  }
}
