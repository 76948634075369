import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { TokenService } from '@consol/token';

@Pipe({
  name: 'accessToken',
})
export class AccessTokenPipe implements PipeTransform {
  constructor(private tokenService: TokenService) { }

  transform(value: any, ...args: any[]) {
    return this.tokenService.token$.pipe(
      map((token) => {
        if(value.indexOf('?') == -1) {
          return `${ value }?access_token=${ token }`
        } else {
          return `${ value }&access_token=${ token }`
        }
      })
    );
  }
}
