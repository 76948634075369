import {
  Component, Input, Inject,
  Self,
} from '@angular/core';

import { Processor } from '@i3e/processor';

import { DataPresentation } from './data-presentation';

import { ActionsProcessorEngine, LinksProcessorEngine, BackProcessorEngine } from '../processor';

@Component({
  selector: 'cs-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: [ './data-view.component.less' ]
})
export class DataViewComponent
extends DataPresentation<ActionsProcessorEngine & LinksProcessorEngine> {
  constructor(
    @Inject(Processor) @Self() processor: ActionsProcessorEngine & LinksProcessorEngine & BackProcessorEngine,
  ) {
    super(processor);
  }
}
