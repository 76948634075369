import { ExtendableProperty } from './extendable-property';

export class ContactPhone implements ExtendableProperty {
  id: string;
  dtype: string;
  phone: string;
  default: boolean;

  toString(): string {
    return `${this.phone}`.trim();
  }

  static convert(obj: any): ContactPhone {
    if(obj && !(obj instanceof ContactPhone)) {
      Object.setPrototypeOf(obj, ContactPhone.prototype);
      obj.dtype = 'contactphone';
    }

    return obj;
  }
}
