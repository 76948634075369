import { Component } from '@angular/core';

@Component({
  selector: 'cs-person-select',
  templateUrl: '../template/person-select/person-select.component.html',
  styleUrls: ['../template/person-select/person-select.component.less'],
})
export class PersonSelectComponent {

}
