import {
  Response, Headers, RequestOptions,
  URLSearchParams
} from '@angular/http';

import { HttpClient , HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';

import 'i3e/observable/add/operator/extractAdditional';

import {
  AdditionalObservable,
  DataContext,
} from '@i3e';

import { ServerResolverService } from '@consol/core';

import { XDataService } from './data-service';
import { ConvertableModelType, CommandInfo } from '../model';

function extractAdditional<T>(dataContext: DataContext<T>) {
  return [dataContext.data, { 'context': dataContext }];
}

function isObservable<T>(obj: any): obj is Observable<T> {
  return !!obj && (typeof obj.subscribe === 'function');
}

export abstract class DataApiService<T>
implements XDataService<T> {
  protected static get serviceName() { return null; }
  protected static get modelType(): ConvertableModelType<any> { return null; }

  constructor(
    protected http: HttpClient,
    protected sr: ServerResolverService,
  ) { }

  protected generateUrl(path: string, id: number|null = null) {
    if(id === null) return path;

    return `${ path }/${ id }`;
  }

  protected hookGet(dataContext: DataContext<T>): Observable<DataContext<T>> {
    return of(dataContext);
  }

  protected extractGetFunction() {
    return extractAdditional as any;
  }

  get(id: number|Observable<number>): Observable<T> {
    return ((() => {
      if(!id) return of({
        data: new (<typeof DataApiService>this.constructor).modelType() as T,
      });

      const fn = (id: number) => {
        let url = this.sr.getUrl('api', this.generateUrl((<typeof DataApiService>this.constructor).serviceName, id));
        return this.http.get(url);
      };

      return (isObservable(id))? id.pipe(switchMap(id => fn(id))) : fn(id);
    })().pipe(
      map((dataContext: DataContext<T>) => {
        (<typeof DataApiService>this.constructor).modelType.convert(dataContext.data) as T;
        return dataContext;
      })
    ) as any).extractAdditional(this.extractGetFunction());
  }

  protected extractGetAllFunction<T>() {
    return extractAdditional as any;
  }

  getAll(itemQuery?: {[prop: string]: any}|Observable<{[prop: string]: any}>): Observable<T[]> {
    itemQuery = itemQuery || {};

    const fn = (itemQuery: {[prop: string]: any}) => {
      let url = this.sr.getUrl('api', this.generateUrl((<typeof DataApiService>this.constructor).serviceName));
      let params = new HttpParams({ fromObject: itemQuery });

      return this.http.get(url, { params: params });
    }

    return (((isObservable(itemQuery))? itemQuery.pipe(switchMap((itemQuery) => fn(itemQuery))) : fn(itemQuery)).pipe(
      map((dataContext: DataContext<T[]>) => {
        dataContext.data = dataContext.data || [];
        dataContext.data.forEach((data) => {
          (<typeof DataApiService>this.constructor).modelType.convert(data) as T;
        });
/*
        let page: number = itemQuery.page || 1;
        const itemPerPage: number = 25;
        let total: number = dataContext.data.length;
        dataContext.data = dataContext.data.slice(itemPerPage * (page - 1), itemPerPage * page);

        return Object.assign(dataContext, {
          pgData: {
            page: page,
            itemPerPage: itemPerPage,
            total: total,
          },
          actions: ['add'],
          searchable: true,
        });
*/
        return dataContext;
      })
    ) as any).extractAdditional(this.extractGetAllFunction());
  }

  save(id: number, item: T): Observable<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    let url = this.generateUrl((<typeof DataApiService>this.constructor).serviceName, id);

    return this.http.put(this.sr.getUrl('api', url), item, options).pipe(
      map((body: CommandInfo) => {
        let data = body.data || {};
        return data;
      })
    );
  }

  delete(id: number): Observable<any> {
    if(id === null) throw new Error('Not Found');
    let url = this.generateUrl((<typeof DataApiService>this.constructor).serviceName, id);

    return this.http.delete(this.sr.getUrl('api', url)).pipe(
      map((body: CommandInfo) => {
        let data = body.data || {};
        return data;
      })
    );
  }
}
