import { Component } from '@angular/core';
import { PageEvent } from '@angular/material';

import { ListLoader } from '@i3e/data-loader';
import { searchdata } from '@i3e/model';

@Component({
  selector: 'cs-paginator',
  templateUrl: './template/paginator/paginator.component.html',
  styleUrls: ['./template/paginator/paginator.component.less'],
})
export class PaginatorComponent {
  length = 0;
  pageSize = 0;
  pageSizeOptions = [5, 10, 25, 100];

  constructor(private loader: ListLoader) { }

  pageChange(pageEvent: PageEvent) {
    let pageSearch: searchdata = {
      limit: pageEvent.pageSize,
      offset: pageEvent.pageIndex * pageEvent.pageSize,
    };

    this.loader.search(pageSearch);
  }
}
