import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'cs-back-button',
  templateUrl: './template/back-button/back-button.component.html',
  styleUrls: [ './template/back-button/back-button.component.less' ],
})
export class BackButtonComponent {
  private static readonly defaultProperties = {
    icon: 'chevron_left',
    iconXs: 'backspace',
    label: 'Back',
    color: null,
    type: null
  };

  private _properties: {[key: string]: any};
  @Input('properties') set properties(value) {
    this._properties = {
      ...BackButtonComponent.defaultProperties,
      ...value
    };
  };

  @Output() process: EventEmitter<void>;

  constructor() {
    this._properties = {...BackButtonComponent.defaultProperties};
    this.process = new EventEmitter<void>();
  }

  get properties() { return this._properties }

  processEmit() {
    this.process.emit();
  }
}
