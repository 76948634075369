import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { searchdata } from '@i3e/model';

@Component({
  selector: 'cs-search-term',
  templateUrl: './template/search-term/search-term.component.html',
  styleUrls: [ './template/search-term/search-term.component.less' ],
  exportAs: 'csSearchTerm',
})
export class SearchTermComponent implements OnInit {
  public form: FormGroup;

  private _term: string|null;
  @Input('termValue') set termValue(searchs: searchdata) {
    this._term = null;

    this._term = this.searchdataToString(searchs);
  }

  @Input() filter: any = null;

  get term(): string {
    return this._term;
  }

  @Output('term') termEmitter: EventEmitter<searchdata>;

  @ViewChild('searchBox') searchBox: any;

  constructor() {
    this.form = new FormGroup({
      term: new FormControl(null)
    });
    this.termEmitter = new EventEmitter<searchdata>();
  }

  ngOnInit() {
    this.form.get('term').setValue(this.term || null);
  }

  private parseSearchdata(value: string|null): searchdata {
    const searchs: searchdata = {
      term: null,
      options: {},
      offset: null,
    };

    if(value !== null) {
      const searchTexts = this.form.get('term').value.split(/\s+/);
      const terms = [];

      for(var searchText of searchTexts) {
        var searchPair = searchText.split(':', 2);
        if((searchPair[0] !== '') && (searchPair.length == 2)) {
          searchs.options[searchPair[0]] = searchPair[1];
        } else if(searchText !== '') {
          terms.push(searchText);
        }
      }

      if(terms.length > 0) searchs.term = terms.join(' ');
    }

    return searchs;
  }

  private searchdataToString(searchs: searchdata): string|null {
    const terms = [];
    var space = '';

    if(searchs.options !== null) {
      for(var key in searchs.options) {
        if(searchs.options[key] !== null) terms.push(key + ':' + searchs.options[key]);
      }
    }
    if(terms.length > 0) space = ' ';

    if(searchs.term != null) {
      terms.push(searchs.term);
      space = '';
    }

    return (terms.length > 0)? terms.join(' ') + space : null;
  }

  doSearch() {
    this.termEmitter.emit(this.parseSearchdata(this.form.get('term').value));
  }

  clearSearch() {
    this.form.get('term').setValue(null);
    this.doSearch();
  }

  addOption(name: string, value: any) {
    const searchs = this.parseSearchdata(this.form.get('term').value);
    searchs.options[name] = value;

    this.form.get('term').setValue(this.searchdataToString(searchs) || null);
  }

  removeOption(name: string) {
    const searchs = this.parseSearchdata(this.form.get('term').value);
    searchs.options[name] = null;

    this.form.get('term').setValue(this.searchdataToString(searchs) || null);
  }

  focus(fn?: Function) {
    setTimeout(() => {
      this.searchBox.nativeElement.focus();
      if(typeof fn === 'function') fn();
    }, 10);
  }
}
