import { NgModule } from '@angular/core';
import { SharedModule } from '@consol/shared';
import { CustomModule } from '@consol/custom';
import { RouterModule } from '@angular/router';
//import { MaterialModule } from '@angular/material';

import { I3eModule } from '@i3e';

import { RoleGuardService as RoleGuard } from '@consol/authorization';

import { PersonModule } from './person.module';

import {
  PersonRoutingComponent,
  PersonSelectRoutingComponent,
  PersonListRoutingComponent,
  PersonIndividualItemRoutingComponent, PersonIndividualItemViewRoutingComponent, PersonIndividualItemFormRoutingComponent,
  PersonCorporateItemRoutingComponent, PersonCorporateItemViewRoutingComponent, PersonCorporateItemFormRoutingComponent
} from './person-routing.component';

@NgModule({
  imports: [
    I3eModule,
    SharedModule,
    CustomModule,
    PersonModule,
    //MaterialModule, // must be specific later, now use from SharedModule
    RouterModule.forChild([
      { path: '', component: PersonRoutingComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['MASTER_PERSON_ACCESS'],
        },
        children: [
          { path: '', component: PersonListRoutingComponent,
            canActivate: [RoleGuard],
            data: {
              //label: 'PERSON',
              allowedRoles: ['MASTER_PERSON_LIST'],
            },
          },
          { path: 'add-select', component: PersonSelectRoutingComponent,
            canActivate: [RoleGuard],
            data: {
              label: 'Person Select',
              allowedRoles: ['MASTER_PERSON_CREATE'],
            }
          },
          { path: 'personindividual/add', component: PersonIndividualItemRoutingComponent,
            canActivate: [RoleGuard],
            data: {
              label: 'INDIVIDUAL',
              icon: 'person',
              description: 'ข้อมูลบุคคลธรรมดา',
              selectedType: 'person',
              allowedRoles: ['MASTER_PERSON_CREATE'],
            },
            children: [
              { path: '', component: PersonIndividualItemFormRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'ADD INDIVIDUAL PERSON',
                  allowedRoles: ['MASTER_PERSON_CREATE'],
                },
              },
            ]
          },
          { path: 'personcorporate/add', component: PersonCorporateItemRoutingComponent,
            canActivate: [RoleGuard],
            data: {
              label: 'CORPORATE',
              icon: 'group',
              description: 'ข้อมูลนิติบุคคล',
              selectedType: 'person',
              allowedRoles: ['MASTER_PERSON_CREATE'],
            },
            children: [
              { path: '', component: PersonCorporateItemFormRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'ADD CORPORATE PERSON',
                  allowedRoles: ['MASTER_PERSON_CREATE'],
                },
              },
            ]
          },
          { path: 'personindividual/:id', component: PersonIndividualItemRoutingComponent,
            children: [
              { path: '', component: PersonIndividualItemViewRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'INDIVIDUAL PERSON(View)',
                  allowedRoles: ['MASTER_PERSON_VIEW'],
                },
              },
              { path: 'edit', component: PersonIndividualItemFormRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'INDIVIDUAL PERSON(Edit)',
                  allowedRoles: ['MASTER_PERSON_EDIT'],
                },
              },
            ]
          },
          { path: 'personcorporate/:id', component: PersonCorporateItemRoutingComponent,
            children: [
              { path: '', component: PersonCorporateItemViewRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'CORPORATE PERSON(View)',
                  allowedRoles: ['MASTER_PERSON_VIEW'],
                },
              },
              { path: 'edit', component: PersonCorporateItemFormRoutingComponent,
                canActivate: [RoleGuard],
                data: {
                  label: 'CORPORATE PERSON(Edit)',
                  allowedRoles: ['MASTER_PERSON_EDIT'],
                },
              },
            ]
          },
        ]
      },
    ])
  ],
  exports: [RouterModule],
  declarations: [
    PersonRoutingComponent,
    PersonSelectRoutingComponent,
    PersonListRoutingComponent,
    PersonIndividualItemRoutingComponent, PersonIndividualItemViewRoutingComponent, PersonIndividualItemFormRoutingComponent,
    PersonCorporateItemRoutingComponent, PersonCorporateItemViewRoutingComponent, PersonCorporateItemFormRoutingComponent
  ]
})
export class PersonRoutingModule {}
