import { searchdata } from '../model';

export abstract class ListLoader {
  abstract search(data: searchdata): void;

  static isValid(obj: any): obj is ListLoader {
    return obj
      && (typeof obj.search === 'function')
    ;
  }
}
