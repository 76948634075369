// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//const localhostUrl = '//localhost:8080';
const localhostUrl = 'https://a-api.consol-innovation.com';

export const environment = {
  production: false,
  api: {
    host: localhostUrl,
    path: '/api/',
  },
  tokenData: {
    url: `${localhostUrl}/oauth/v2`,
    client_id: '2_2d6m1egnbuhw0cgk888owwskk0w4c0wg0oksow8ogg4www0co8',
    secret: '26ijx5m68clc4kcs08ckcckwo8k4ow8og4cow4wcwgoowwk40k',
  },
};
