import {
  Component, EventEmitter, forwardRef,
  Output, Input,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR, NG_VALIDATORS,
} from '@angular/forms';

import { Observable, of } from 'rxjs';

import { switchMap, tap, debounceTime, filter } from 'rxjs/operators';

import { SearchableDataControl } from '../searchable-data-control';

export const csSearchableItemControlValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchableItemControlComponent),
  multi: true,
};

export const csSearchableItemControlValidators: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SearchableItemControlComponent),
  multi: true,
}

@Component({
  selector: 'cs-searchable-item-control',
  templateUrl: './searchable-item-control.component.html',
  styleUrls: ['./searchable-item-control.component.less'],
  providers: [csSearchableItemControlValueAccessor, csSearchableItemControlValidators],
  exportAs: 'csSearchableItemControl',
})
export class SearchableItemControlComponent<T>
extends SearchableDataControl<T> {
  @Output('searchChange') private searchChangeEmitter: EventEmitter<string>;

  @Input('newTemplate') private _newTemplate: any;

  private newTemplateHandler: {close: Function};

  constructor() {
    super();

    this.searchChangeEmitter = new EventEmitter<string>();
    this._newTemplate = null;
    this.newTemplateHandler = null;
  }

  get newTemplate() { return (this.canUpdate)? this._newTemplate : null; }

  writeToControl(obj: T) {
    this.control.setValue(obj);
  }

  protected applyControlValueChange(observable: Observable<any>) {
    let lastSearchValue = null;
    observable.pipe(
      switchMap((value) => {
        if(typeof value === 'object'){
          this.tryUpdateValue(value);
          return of(null);
        }

        if(this.freeEnter) this.tryUpdateValue(value);

        return of(value).pipe(
          filter((value) => (value && (value.length >= this.minTypeLength))),
          filter((value) => (value && (value !== lastSearchValue)))
        );
      }),
      debounceTime(500),
      //.tap((value) => console.debug('after debounce:', value))
    ).subscribe((value) => {
      lastSearchValue = value;
      this.searchChangeEmitter.emit(value);
    });
  }

  isObject(value: any): boolean {
    return value && typeof value === 'object';
  }

  reset() {
    if(!this.freeEnter) setTimeout(() => {
      this.control.setValue(this.value);
    }, 500);
  }

  clear() {
    this.control.setValue(null);
  }

  setNewTemplateHandler(handler: {close: Function}) {
    this.newTemplateHandler = handler;
  }

  createValue(data: T) {
//console.debug('new data:', data);
    if(data !== null) this.writeToControl(data);
    this.newTemplateHandler.close();
  }
}
