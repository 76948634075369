import { ExtendableProperty } from './extendable-property';

export class Address implements ExtendableProperty {
  id: string;
  dtype: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  postalcode: string;

  toString(): string {
    const result = [];
    if(this.address) result.push(this.address.trim());
    if(this.subdistrict) result.push((this.subdistrict).trim());
    if(this.district) result.push((this.district).trim());
    if(this.province) result.push((this.province).trim());
    if(this.postalcode) result.push(this.postalcode.trim());
    return result.join(' ');
  }

  static convert(obj: any): Address {
    if(obj && !(obj instanceof Address)) {
      Object.setPrototypeOf(obj, Address.prototype);
      obj.dtype = 'address';
    }

    return obj;
  }
}
