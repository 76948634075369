import { Injectable } from '@angular/core';
import {
  Route,
  CanActivate, CanLoad,
  ActivatedRouteSnapshot
} from '@angular/router';

import { map } from 'rxjs/operators';

import { AuthorizationService } from './authorization.service';

@Injectable()
export class RoleGuardService implements CanActivate, CanLoad {
  constructor(private authorizationService: AuthorizationService) { }

  protected allowed(allowedRoles) {
    return this.authorizationService.authenticated$.pipe(
      map(() => this.authorizationService.allowed(allowedRoles))
    );
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.allowed(route.data.allowedRoles);
  }

  canLoad(route: Route) {
    return this.allowed(route.data.allowedRoles);
	}
}
