import { Pipe, PipeTransform } from '@angular/core';

import { FileUploadService } from './file-upload.service';

@Pipe({
  name: 'fileUploadUrl',
})
export class FileUploadUrlPipe implements PipeTransform {
  constructor(private service: FileUploadService) { }

  transform(value: any, ...args: any[]) {
    return this.service.fileUrl(value);
  }
}
