import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { searchdata } from '../model';

@Injectable()
export class SearchRoutingService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  search(searchs: searchdata) {
    const params:{[name: string]: any} = { };

    for(var name in searchs) {
      if(name === 'term') {
        params['search'] = searchs[name];
      } else if(name === 'options') {
        for(var paramName in this.activatedRoute.snapshot.queryParams) {
          if(paramName.indexOf('where:') === 0) params[paramName] = null;
        }
        for(var optionName in searchs[name]) {
          params['where:' + optionName] = searchs[name][optionName];
        }
      } else {
        params[name] = searchs[name];
      }
    }

    let queryParams = Object.assign({}, this.activatedRoute.snapshot.queryParams, params);
    this.router.navigate([], {
      queryParams: queryParams,
      replaceUrl: true,
    });
  }
}
