import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'corporateCode',
})
export class CorporateCodePipe implements PipeTransform {
  constructor() { }

  transform(value: string, ...args: any[]) {
    return (value)? value.split('/', 2)[(args[0])? 1 : 0]: value;
  }
}
