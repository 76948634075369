import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { Observable, Subject } from 'rxjs';

import { LoginData } from '../model';

@Component({
  selector: 'cs-login-dialog',
  template: '<cs-login (post)="update($event)"></cs-login>',
})
export class LoginDialogComponent {
  private subject: Subject<LoginData>;
  readonly loginData$: Observable<LoginData>;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.subject = new Subject<LoginData>();
    this.loginData$ = this.subject.asObservable();
  }

  update(loginData: LoginData) {
    this.subject.next(loginData);
    this.dialogRef.close();
  }
}
