import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServerResolverService } from '@consol/core';

import {
  DataApiService,
  ConvertableModelType,
} from '@consol/shared';

import { Person } from './model';

import { PersonService } from './person.service';

@Injectable()
export class PersonApiService
extends DataApiService<Person>
implements PersonService {
  protected static get serviceName() { return 'person'; }
  protected static get modelType() { return Person as ConvertableModelType<Person>; }

  constructor(
    http: HttpClient,
    sr: ServerResolverService
  ) {
    super(http, sr);
  }
}
