import { Directive, Input, HostBinding } from "@angular/core";
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from "@angular/forms";

@Directive({
  selector: '[max]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }]
})
export class MaxValidatorDirective implements Validator {
  private onValidatorChange?: () => void;
  private _max: number | null = null;

  @HostBinding('attr.max')
  @Input()
  set max(value) {
    if(value !== null) {
      if(typeof value === 'string') {
        value = +value;
      }

      value = Math.round(value * 1000) / 1000;
    }

    this._max = value;

    if(this.onValidatorChange) {
      this.onValidatorChange();
    }
  }

  get max() {
    return this._max;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.max === null) {
      return null;
    }

    const accual = +control.value;
    const max = this.max;

    if(isNaN(accual) || accual > max) {
      return {
        max: {
          max: max,
          accual: accual,
        },
      };
    } else {
      return null;
    }
  }
}
