import {
  Directive, forwardRef, Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { DataGetAll } from '../../data-service';

import { dataLoaderDirectiveName } from '../predefine';

import { DataLoaderEngine } from '../data-loader-engine';

import { DataRoutingLoader } from './data-routing-loader';

import { ListLoader } from '../list-loader';

import { searchdata } from '../../model';

import { SearchRoutingService } from '../../routing-service';

export const i3eListRoutingLoader: any = {
  provide: ListLoader,
  useExisting: forwardRef(() => ListRoutingLoaderDirective),
};

export const i3eListRoutingLoaderEngine: any = {
  provide: DataLoaderEngine,
  useExisting: forwardRef(() => ListRoutingLoaderDirective),
};

@Directive({
  selector: `[${ dataLoaderDirectiveName }="listRouting"]`,
  providers: [ i3eListRoutingLoader, i3eListRoutingLoaderEngine ],
})
export class ListRoutingLoaderDirective<T, E extends DataGetAll<T>>
extends DataRoutingLoader<{[key: string]: any}, T, E>
implements ListLoader {
  @Input() fullList: boolean;
  constructor(
    activatedRoute: ActivatedRoute,
    private searchRoutingService: SearchRoutingService,
  ) {
    super(activatedRoute);

    this.fullList = false;
  }

  observable(): Observable<{[key: string]: any}> {
    return this.activatedRoute.queryParams;
  }

  load(params: {[key: string]: any}): Observable<T> {
    let itemQuery = Object.assign((this.fullList)? {} : { limit: 25, offset: 0 }, params);

    return this.dataService.getAll(itemQuery);
  }

  search(searchs: searchdata) {
    this.searchRoutingService.search(searchs);
  }
}
