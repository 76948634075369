import {
  Directive, forwardRef
} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ProcessorEngine } from '@i3e/processor';

import { routeBackable } from '../util';

import { BackProcessorEngine } from './back-processor-engine';

export const csBackRoutingProcessorEngineDirective = {
  provide: ProcessorEngine,
  useExisting: forwardRef(() => BackRoutingProcessorEngineDirective),
  multi: true,
};

export const csBackRoutingBackProcessorEngineDirective = {
  provide: BackProcessorEngine,
  useExisting: forwardRef(() => BackRoutingProcessorEngineDirective),
};

@Directive({
  selector: '[csBackRoutingProcessor]',
  providers: [
    csBackRoutingProcessorEngineDirective,
    csBackRoutingBackProcessorEngineDirective,
  ],
})
export class BackRoutingProcessorEngineDirective
implements BackProcessorEngine {
  constructor(
    activatedRoute: ActivatedRoute,
    location: Location,
  ) {
    this.backable = routeBackable(activatedRoute);
    this.back = () => location.back();
  }

  readonly backable: boolean;

  readonly back: () => void;
}
