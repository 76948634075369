import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneLess',
})
export class TimezoneLessPipe implements PipeTransform {
  constructor() { }

  transform(value: any) {
    if((value === null) || (value === undefined)) return value;
    var newDate = new Date(value);

    newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);

    return newDate;
  }
}
