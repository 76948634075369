import { Directive, Optional, Self, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

let index = 0;

@Directive({
  selector: 'textarea[wrap="hard"]'
})
export class WrapHardDirective implements OnInit {
  private uid: string;
  private lastValue: any;
  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private elemRef: ElementRef,
  ) {
    this.uid = 'textarea-name-' + index++;
  }

  ngOnInit(): void {
    const nativeElem = this.elemRef.nativeElement;
    if(nativeElem) {
      if(!nativeElem.name) nativeElem.name = this.uid;

      if(this.ngControl) {
        this.ngControl.valueChanges.pipe(
          debounceTime(100),
        )
        .subscribe((value) => {
          if(value != this.lastValue) {
            let settingValue = value;
            const form = nativeElem.form;
            if(form) {
              if(value != nativeElem.value) nativeElem.value = value;
              const formData = new FormData(form);
              settingValue = formData.get(nativeElem.name);
            }
            this.lastValue = settingValue;
            this.ngControl.control.setValue(settingValue);
          }
        });
      }
    }
  }
}
