import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServerResolverService } from '@consol/core';

import {
  DataApiService,
  ConvertableModelType,
} from '@consol/shared';

import { PersonCorporate } from './model';

import { PersonCorporateService } from './person-corporate.service';

@Injectable()
export class PersonCorporateApiService extends DataApiService<PersonCorporate>
implements PersonCorporateService {
  protected static get serviceName() { return 'person-corporate'; }
  protected static get modelType() { return PersonCorporate as ConvertableModelType<PersonCorporate>; }

  constructor(
    http: HttpClient,
    sr: ServerResolverService
  ) {
    super(http, sr);
  }
}
