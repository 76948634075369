import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor() { }

  transform(value: any, ...args: any[]) {
    let minFrac = 2;
    let maxFrac = 2;
    if(args.length == 1) {
      minFrac = args[0];
      maxFrac = args[0];
    } else if(args.length == 2) {
      minFrac = args[0];
      maxFrac = args[1];
    }
    return (+value).toLocaleString(undefined, {minimumFractionDigits: minFrac, maximumFractionDigits: maxFrac});
  }
}
