import {
  Directive, forwardRef, Input,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';

import { ProcessorEngine } from '@i3e/processor';

import { DataSave } from '@i3e/data-service';

import { DataLoaderProcessorEngine } from './data-loader-processor-engine';
import { FormProcessorEngine } from './form-processor-engine';

export const csFormRoutingProcessorEngineDirective = {
  provide: ProcessorEngine,
  useExisting: forwardRef(() => FormRoutingProcessorEngineDirective),
  multi: true,
};

export const csFormRoutingFormProcessorEngineDirective = {
  provide: FormProcessorEngine,
  useExisting: forwardRef(() => FormRoutingProcessorEngineDirective),
};

@Directive({
  selector: '[csFormRoutingProcessor]',
  providers: [
    csFormRoutingProcessorEngineDirective,
    csFormRoutingFormProcessorEngineDirective,
  ],
})
export class FormRoutingProcessorEngineDirective<T, ES, DS extends DataSave<T, ES>>
implements FormProcessorEngine<T, ES> {
  @Input('dataService') private _dataService: DS;
  @Input('identifier') private identifier: string;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _processor: DataLoaderProcessorEngine<T>,
  ) {
    this.identifier = 'id';
  }

  protected _back() {
    this._location.back();
  }

  protected _replace(info) {
    const navigate = ['..', info[this.identifier]];
    let relativeTo = this._activatedRoute.parent;
    while(relativeTo.snapshot.data.skipRoutingReplace) relativeTo = relativeTo.parent;

    this._router.navigate(navigate, {
      relativeTo: relativeTo,
      replaceUrl: true,
    });
  }

  protected _backAndReplace(info) {
    this._back();
    setTimeout(() => this._replace(info), 500);
  }

  readonly save = (data: T): Observable<ES> => {
    const id = (data as any)[this.identifier];
    const isNew = !id;

    const infoSubject = new ReplaySubject<ES>(1);
    const observable = this._dataService.save(id || null, data);
    observable.subscribe((info) => {
      infoSubject.next(info);
      if(isNew){
        this._replace(info);
      } if(id != info[this.identifier]) {
        this._backAndReplace(info);
      }else{
        this._back();
        setTimeout(() => this._processor.reload(), 10);
      }
    }, (err) => {
      infoSubject.error(err);
    });

    return infoSubject.asObservable();
  }

  readonly cancel = (): void => {
    this._back();
  }
}
