import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServerResolverService } from '@consol/core';

import {
  DataApiService,
  ConvertableModelType,
} from '@consol/shared';

import { PersonIndividual } from './model';

import { PersonIndividualService } from './person-individual.service';

@Injectable()
export class PersonIndividualApiService extends DataApiService<PersonIndividual>
implements PersonIndividualService {
  protected static get serviceName() { return 'person-individual'; }
  protected static get modelType() { return PersonIndividual as ConvertableModelType<PersonIndividual>; }

  constructor(
    http: HttpClient,
    sr: ServerResolverService
  ) {
    super(http, sr);
  }
}
