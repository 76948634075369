import {
  Directive, forwardRef, Input,
} from '@angular/core';

import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';

import { switchMap, distinctUntilChanged } from 'rxjs/operators';

import { dataLoaderDirectiveName } from '../predefine';

import { DataLoaderEngine } from '../data-loader-engine';

export const i3eFuncitoningLoaderEngine: any = {
  provide: DataLoaderEngine,
  useExisting: forwardRef(() => FunctioningLoaderDirective),
};

@Directive({
  selector: `[${ dataLoaderDirectiveName }="functioning"]`,
  providers: [ i3eFuncitoningLoaderEngine ],
})
export class FunctioningLoaderDirective<T>
implements DataLoaderEngine<T, T> {
  private observableWithSubject: Subject<Function>;

  @Input('observableWith') private set observableWIth(observableFn: Function) {
    this.observableWithSubject.next(observableFn);
  };

  constructor() {
    this.observableWithSubject = new ReplaySubject<Function>(1);
  }

  observable(): Observable<T> {
    return this.observableWithSubject.asObservable().pipe(
      distinctUntilChanged(),
      switchMap((observableWith) => observableWith() as Observable<T>)
    );
  }

  load(data: T): Observable<T> {
    return of(data);
  }

  search(params: {[key: string]: any}): void { }
}
