import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { map, filter, catchError, take } from 'rxjs/operators';

import { ServerResolverService } from '@consol/core';

import { SystemUser } from '../model';

import { AuthorizationService } from './authorization.service';

@Injectable()
export class AuthorizationApiService implements AuthorizationService {
  private systemUser: SystemUser;
  private authenticated$$: BehaviorSubject<boolean>;
  private _authenticated$: Observable<void>;

  constructor(
    private http: HttpClient,
    private sr: ServerResolverService
  ) {
    this.systemUser = null;
    this.authenticated$$ = new BehaviorSubject<boolean>(false);
    this._authenticated$ = this.authenticated$$.asObservable().pipe(
      filter((value) => value),
      map(()=> undefined)
    );
  }

  loadSystemUser(clearBeforeLoading: boolean = false): Observable<SystemUser> {
    if(clearBeforeLoading) this.clearSystemUser();

    let url = this.sr.getUrl('api', 'token/info');
    return this.http.get(url).pipe(
      map((dataContext: any) => {
        this.systemUser = SystemUser.convert(dataContext.data);
        this.authenticated$$.next(!!this.systemUser);
        return this.getSystemUser();
      }),
      catchError((error) => {
        console.error('loadSystemUser:', error);
        this.systemUser = null;
        return of(this.getSystemUser());
      }),
    );
  }

  clearSystemUser(): void {
    this.systemUser = null;
  }

  affectiveRoles(roles: string[]): string[] {
    if(!this.systemUser) return [];
    if('root' === this.systemUser.username) return roles.slice(0);
    return roles.filter((role) => this.systemUser.roles.indexOf(role) !== -1);
  }

  allowed(roles: string[]): boolean {
    return this.affectiveRoles(roles).length > 0;
  }

  getSystemUser(): SystemUser {
    return this.systemUser;
  }

  get authenticated$() { return this._authenticated$.pipe(take(1)); }
}
