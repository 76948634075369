import { Observable } from 'rxjs';
import { extractAdditional } from '../../operator/extractAdditional';

Observable.prototype['extractAdditional'] = extractAdditional;

// declare module 'rxjs' {
//   interface Observable<T> {
//     extractAdditional: typeof extractAdditional;
//   }
// }
