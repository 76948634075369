import { Account } from '@consol/core';

export class SystemAccount extends Account {
  systemId: string;
  roles: string[];     // virtual
  individualRoles: string[];
  systemGroups: SystemGroup[];

  toString(): string {
    return `${this.systemId} ${this.name}`;
  }

  static convert(obj: any): SystemAccount {
    if (obj && !(obj instanceof SystemAccount)) {
      Object.setPrototypeOf(Account.convert(obj), SystemAccount.prototype);
      (obj.systemGroups || []).forEach((data) => SystemGroup.convert(data));
    }

    return obj;
  }
}

export class SystemUser extends SystemAccount {
  get username(): string { return this.systemId; }
  set username(value: string) { this.systemId = value; }

  plainPassword: string;

  static convert(obj: any): SystemUser {
    if (obj && !(obj instanceof SystemUser)) {
      Object.setPrototypeOf(SystemAccount.convert(obj), SystemUser.prototype);
    }

    return obj;
  }
}

export class SystemGroup extends SystemAccount {
  //accounts: SystemAccount[];

  static convert(obj: any): SystemGroup {
    if (obj && !(obj instanceof SystemGroup)) {
      Object.setPrototypeOf(SystemAccount.convert(obj), SystemGroup.prototype);
      //(obj.accounts || []).forEach((data) => SystemAccount.convert(data));
    }

    return obj;
  }
}

export class SystemClient extends SystemAccount {
  secret: string;
  redirectUris: string[];
  allowedGrantTypes: string[];

  static convert(obj: any): SystemClient {
    if (obj && !(obj instanceof SystemClient)) {
      Object.setPrototypeOf(SystemAccount.convert(obj), SystemClient.prototype);
    }

    return obj;
  }
}
