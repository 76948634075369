import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServerResolverService } from '@consol/core';

import {
  DataApiService,
  ConvertableModelType,
} from '@consol/shared';

import { Account } from '@consol/core';

import { AccountService } from './account.service';

@Injectable()
export class AccountApiService
extends DataApiService<Account>
implements AccountService {
  protected static get serviceName() { return 'core-account'; }
  protected static get modelType() { return Account as ConvertableModelType<Account>; }

  constructor(
    http: HttpClient,
    sr: ServerResolverService
  ) {
    super(http, sr);
  }
}
