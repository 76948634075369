import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SystemUser } from '@consol/authorization/model';
import { ServerResolverService } from './server-resolver.service';
import { Employee } from '@consol/employee/model';

@Injectable()
export class SystemEmployeeService {
  private employee$$: BehaviorSubject<Employee|null>;
  private _employee$: Observable<Employee>;
  constructor(
    private rs: ServerResolverService,
    private http: HttpClient,
  ) {
    this.employee$$ = new BehaviorSubject(null);
    this._employee$ = this.employee$$.asObservable();
  }

  loadEmployee(user: SystemUser): Observable<Employee> {
    return this.http.get<{data: Array<{dtype: string}>}>(this.rs.getUrl('api', `core-account-related/${user.id}`)).pipe(
      map((res) => {
        const relateds = res.data;
        let result: Employee = null;
        for(let item of relateds) {
          if(item.dtype === 'employee') {
            result = Employee.convert(item);
            break;
          }
        }

        this.employee$$.next(result);

        return result;
      })
    );
  }

  get employee$() { return this._employee$; }
}
