import { Component, EventEmitter, Output } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginData } from '../model';

@Component({
  selector: 'cs-login',
  templateUrl: '../template/login/component.html',
  styleUrls: ['../template/login/component.less'],
})
export class LoginComponent {
  readonly formGroup: FormGroup;

  @Output() post: EventEmitter<LoginData>;

  showScope: boolean;

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      scope: [null, Validators.required],
    });

    this.post = new EventEmitter<LoginData>();
    this.showScope = true;
  }

  formSubmit(ev: Event) {
    ev.preventDefault();

    this.formGroup.updateValueAndValidity();
    if(this.formGroup.valid) {
      this.post.emit(this.formGroup.value);
    }
  }
}
