import {
  Component, ViewEncapsulation,
  AfterViewInit, OnDestroy, ViewChild,
  ElementRef,
} from '@angular/core';

import { Route, Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material';

import { Observable, Subscription } from 'rxjs';

import { tap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { GlobalState, GlobalStateService } from '@i3e/global-state';

import { MediaMonitor } from '@angular/flex-layout';

import { appRoutes } from '../../app-routes';

import { AuthorizationService } from '@consol/authorization';

import { TokenService } from '@consol/token';

import { ServerResolverService } from '@consol/core';

import {
  trigger,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

const DEFAULT_COMPONENTINFO = {
  label: 'Module'
};

@Component({
  selector: 'app-layout',
  templateUrl: '../template/app-layout/component.html',
  styleUrls: ['../template/local.less', '../template/app-layout/component.less'],
  animations: [
    trigger('changeState', [
      transition(':enter', [   // :enter is alias to 'void => *'
        animate(250, keyframes([
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(15px)', offset: 0.3 }),
          style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 })
        ]))
      ])
    ])
  ]
})
export class AppLayoutComponent implements AfterViewInit, OnDestroy {
  private _routes: Routes = appRoutes;
  public routeData: any = DEFAULT_COMPONENTINFO;
  public componentInfo: GlobalState;

  @ViewChild('sideMenu') private _sideMenu: MatSidenav;

  private _small: boolean = false;

  private subscribtions: Subscription[];

  scope$: Observable<string>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _mediaMonitor: MediaMonitor,
    componentInfoService: GlobalStateService,
    private authorizationService: AuthorizationService,
    private tokenService: TokenService,
  ) {
    this.componentInfo = componentInfoService.globalState;
    this.subscribtions = [];

    this.scope$ = this.tokenService.scope$;
  }

  get routes() {
    return this._routes.filter((route) => {
      if (route.data && route.data.allowedRoles) {
        return this.authorizationService.allowed(route.data.allowedRoles);
      }

      return true;
    });
  }

  get systemUser() {
    return this.authorizationService.getSystemUser();
  }

  get small() {
    return this._small;
  }

  get header() {
    let message: any = null;
    if (this.componentInfo) {
      message = this.componentInfo.error || this.componentInfo.label
    }

    return message || this.routeData.label;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let routerChangeHandler = this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      );

      this.subscribtions.push(
        routerChangeHandler.pipe(
          map(() => this._route),
          map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data),
        ).subscribe((data) => {
          this.routeData = data;
        })
      );

      this.subscribtions.push(
        this._mediaMonitor.observe('xs').subscribe((mediaChain) => {
          this._small = mediaChain.matches;
        })
      );

      this.subscribtions.push(
        this._mediaMonitor.observe('gt-md').subscribe((mediaChain) => {
          if (mediaChain.matches) {
            if (this._sideMenu.mode !== 'side') {
              //this._sideMenu.align = 'start';
              this._sideMenu.position = 'start';
              this._sideMenu.open();
            }
            this._sideMenu.mode = 'side';
          } else {
            if (this._sideMenu.mode !== 'over') {
              //this._sideMenu.align = 'start';
              this._sideMenu.position = 'start';
              this._sideMenu.close();
            }
            this._sideMenu.mode = 'over';
          }
        })
      );
    }, 100);
  }

  ngOnDestroy() {
    this.subscribtions.forEach((subscribtion) => subscribtion.unsubscribe());
  }

  getMenuLabel(route: Route) {
    return (route.data) ? route.data.label || route.path : route.path;
  }

  getMenuText(route: Route) {
    return (route.data) ? route.data.text || route.path : route.path;
  }

  getMenuName(route: Route) {
    return (route.data) ? route.data.name || route.path : route.path;
  }

  tryCloseSideMenu() {
    if (this._sideMenu.mode !== 'side') this._sideMenu.close();
  }

  logout(ev) {
    this.tokenService.deleteToken().pipe(
      switchMap(() => this.authorizationService.loadSystemUser(true)),
    ).subscribe(
      (systemUser) => {
        //console.debug('SystemUser:', systemUser);
      }
    );
  }
}
