import { ActivatedRoute } from '@angular/router';

export function routeBackable(activatedRoute: ActivatedRoute): boolean {
  while(activatedRoute) {
    const routeConfig = activatedRoute.routeConfig;
    if(routeConfig.path !== '') {
      return (routeConfig.data && (typeof routeConfig.data.backable !== 'undefined'))? !!routeConfig.data.backable : true;
    }
    activatedRoute = activatedRoute.parent;
  }

  return false;
}
