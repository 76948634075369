import { Component } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';

import { StateLabelConfig } from '@i3e/global-state';
import { AccountStateLabelConfig } from '@consol/account';

import { PersonFormService } from './person-form.service';
import { PersonService } from './person.service';
import { PersonCorporateService } from './person-corporate.service';
import { PersonIndividualService } from './person-individual.service';
import { Person } from './model';

export const csPersonStateLabelConfig = new AccountStateLabelConfig<Person>('Person');

@Component({
  selector: 'cs-person-root-routing',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./template/person-routing/person-routing.component.less'],
  providers: [
    {provide: StateLabelConfig, useValue: csPersonStateLabelConfig },
  ],
})
export class PersonRoutingComponent { }

@Component({
  selector: 'cs-person-list-routing',
  template: '<cs-person-list i3eDataLoader="listRouting" [dataService]="ds" i3eProcessor csContextProcessor csItemRoutingProcessor csBackRoutingProcessor [descriptionOverride]="descriptionOverride"></cs-person-list>',
  styleUrls: ['./template/person-routing/person-routing.component.less'],
})
export class PersonListRoutingComponent {
  readonly descriptionOverride = {
    'add-select': { icon: 'add_circle_outline', color: 'primary', label: 'สร้าง' },
  };

  constructor(
    public ds: PersonService,
  ) { }
}

@Component({
  selector: 'cs-person-select-routing',
  templateUrl: './template/person-routing/person-select-routing.component.html',
  styleUrls: ['./template/person-routing/person-select-routing.component.less'],
})
export class PersonSelectRoutingComponent {
  public routes: Route[];

  constructor(private activatedRoute: ActivatedRoute) {
    // TODO: backable
    this.routes = this.activatedRoute.parent.routeConfig.children
      .filter((route) =>
        route.data && route.data['selectedType'] &&
        (route.data['selectedType'] === 'person'))
    ;
  }
}

@Component({
  selector: 'cs-person-corporate-item-routing',
  template: '<cs-person-corporate-item i3eDataLoader="itemRouting" [dataService]="ds" i3eProcessor csContextProcessor csBackRoutingProcessor><router-outlet></router-outlet></cs-person-corporate-item>',
  styleUrls: ['./template/person-routing/person-routing.component.less']
})
export class PersonCorporateItemRoutingComponent {
  constructor(
    public ds: PersonCorporateService,
  ){ }
}

@Component({
  selector: 'cs-person-individual-item-routing',
  template: '<cs-person-individual-item i3eDataLoader="itemRouting" [dataService]="ds" i3eProcessor csContextProcessor csBackRoutingProcessor><router-outlet></router-outlet></cs-person-individual-item>',
  styleUrls: ['./template/person-routing/person-routing.component.less']
})
export class PersonIndividualItemRoutingComponent {
  constructor(
    public ds: PersonIndividualService,
  ){ }
}

@Component({
  selector: 'cs-person-corporate-item-view-routing',
  template: '<cs-person-corporate-item-view i3eDataLoader="dataPreloading" csRoutingState="view"></cs-person-corporate-item-view>',
  styleUrls: ['./template/person-routing/person-routing.component.less'],
})
export class PersonCorporateItemViewRoutingComponent {
  constructor(
    public ds: PersonCorporateService,
  ){ }
}

@Component({
  selector: 'cs-person-individual-item-view-routing',
  template: '<cs-person-individual-item-view i3eDataLoader="dataPreloading" csRoutingState="view"></cs-person-individual-item-view>',
  styleUrls: ['./template/person-routing/person-routing.component.less'],
})
export class PersonIndividualItemViewRoutingComponent {
  constructor(
    public ds: PersonIndividualService,
  ){ }
}

@Component({
  selector: 'cs-person-corporate-item-form-routing',
  template: '<cs-person-corporate-item-form i3eDataLoader="dataPreloading" i3eProcessor csFormRoutingProcessor [dataService]="ds" csRoutingState="form"></cs-person-corporate-item-form>',
  styleUrls: ['./template/person-routing/person-routing.component.less'],
})
export class PersonCorporateItemFormRoutingComponent {
  constructor(
    public ds: PersonCorporateService,
  ){ }
}

@Component({
  selector: 'cs-person-individual-item-form-routing',
  template: '<cs-person-individual-item-form i3eDataLoader="dataPreloading" i3eProcessor csFormRoutingProcessor [dataService]="ds" csRoutingState="form"></cs-person-individual-item-form>',
  styleUrls: ['./template/person-routing/person-routing.component.less'],
})
export class PersonIndividualItemFormRoutingComponent {
  constructor(
    public ds: PersonIndividualService,
  ){ }
}
