import { Component } from '@angular/core';

@Component({
  selector: 'app-blank',
  templateUrl: '../template/blank-layout/component.html',
  styleUrls: ['../template/local.less', '../template/blank-layout/component.less'],
})
export class BlankLayoutComponent {
  constructor() { }
}
