import { Injectable } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material';

import { Observable, ConnectableObservable } from 'rxjs';
import { of } from 'rxjs';

import { map, mergeMap, catchError, filter, publish, publishLast, switchMap, tap } from 'rxjs/operators';

import { LoginData } from '../model';

import { LoginDialogComponent } from '../component/login-dialog.component';

@Injectable()
export class LoginDataService {

  constructor(public dialog: MatDialog) {}

  getLoginData(): Observable<LoginData> {
    return this.openDialog().componentInstance.loginData$;
  }

  openDialog(): MatDialogRef<LoginDialogComponent> {
    let dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '100% !important',
      autoFocus: true,
      closeOnNavigation: false,
      disableClose: true,
      data: { },
    }) as MatDialogRef<LoginDialogComponent>;

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    return dialogRef;
  }
}
