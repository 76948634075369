import { Directive, Component, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[navigationBack]'
})
export class NavigationBackDirective {
  constructor(private _location: Location) { }

  @HostListener('click', ['$event'])
  onClick(ev) {
    this._location.back();
  }
}

@Component({
  selector: 'navigation-back',
  template: `<cs-back-button (process)="onClick()"></cs-back-button>`,
})
export class NavigationBackComponent {
  constructor(private _location: Location) { }

  onClick() {
    this._location.back();
  }
}
